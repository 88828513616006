import Clock from "./Graphics/Clock.js";
import ClockManual from "./Graphics/ClockManual.js";
import MatchID from "./Graphics/MatchID.js";
import Penalties from "./Graphics/Penalties.js";
import PenaltiesLT from "./Graphics/PenaltiesLT.js";
import Scorer from "./Graphics/Scorer.js";
import ScoreSlate from "./Graphics/ScoreSlate.js";
import ScoreUpdate from "./Graphics/ScoreUpdate.js";
import Versus from "./Graphics/Versus.js";
const COMP_CODE_MAPPINGS = [
  { opta: "LEO", img: "L1" },
  { opta: "LET", img: "L2" },
  { opta: "CHA", img: "CH" },
  { opta: "LEC", img: "EC" },
  { opta: "LEC", img: "EC" },
  { opta: "FLT", img: "ET" },
];
export default class Match {
  constructor({
    match,
    project,
    queue,
    filename = "EFL_24_25_${COMPETITION}_${HOME_TEAM}_${AWAY_TEAM}_G_${GRAPHIC}" +
      (process.env.TIMECODE_FILES ? "_{TIMECODE}" : ""),
  }) {
    this.filename = filename
      ?.replace(
        "${COMPETITION}",
        COMP_CODE_MAPPINGS?.find((c) => c?.opta === match?.competition?.code)
          ?.img ?? match?.competition?.code
      )
      ?.replace(
        "${HOME_TEAM}",
        match?.home_team?.variables?.render_code ?? match?.home_team?.code
      )
      ?.replace(
        "${AWAY_TEAM}",
        match?.away_team?.variables?.render_code ?? match?.away_team?.code
      );
    this.graphics = [
      new MatchID({ match, project, queue, filename: this.filename }),
      new ScoreSlate({
        match,
        project,
        queue,
        filename: this.filename,
      }),
      // new ScoreUpdate({
      //   match,
      //   project,
      //   queue,
      //   filename: this.filename,
      // }),
      new Versus({ match, project, queue, filename: this.filename }),
      new ClockManual({
        match,
        project,
        queue,
        filename: this.filename,
      }),
      new Penalties({ match, project, queue, filename: this.filename }),
      // new PenaltiesLT({
      //   match,
      //   project,
      //   queue,
      //   filename: this.filename,
      // }),
    ];
  }
}
