import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { APIContext } from "../contexts/APIContext";
import moment from "moment";
import { Button, ScrollBar } from "../Theme/Hyper";
import { useNavigate, useParams } from "react-router-dom";

const Main = styled.div`
  display: flex;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  width: 30%;
  height: 100%;
  flex-direction: column;
`;
const GamesDiv = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
  overflow-y: auto;
  height: 95vh;
`;
const Game = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5em;
  justify-content: center;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  background-color: ${(props) =>
    props.sub ? " #181d25" : props.selected ? "#db0a41" : "#202731"};
  border-bottom: 2px solid #181d25;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
`;
const Team = styled.div`
  display: flex;
  margin: 0.5em;
  width: 45%;
  justify-content: ${(props) => (props.right ? "flex-end" : "")};
`;
const Verses = styled.div`
  width: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
`;

const DayButton = styled(Button)`
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "rgb(32, 39, 49)"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
`;

function Games({ setSelecetdRow }) {
  const navigate = useNavigate();
  const { getFixtures } = useContext(APIContext);
  const [fixtures, setFixtures] = useState([]);
  const [day, setDay] = useState(0);
  let { gameID } = useParams();
  useEffect(() => {
    getFixtures({
      date: moment()?.format("YYYY-MM-DD") + "Z",
      // date: "2024-08-24Z",
    }).then((data) => {
      setFixtures(JSON.parse(JSON.stringify(data)));
    });
  }, []);
  return (
    <Main>
      <Row>
        <DayButton
          green={day === -1}
          onClick={() => {
            setDay(-1);
            getFixtures({
              date: moment()?.subtract(1, "day")?.format("YYYY-MM-DD") + "Z",
              // date: "2024-08-24Z",
            }).then((data) => {
              setFixtures(JSON.parse(JSON.stringify(data)));
            });
          }}
        >
          Yesterday
        </DayButton>
        <DayButton
          green={day === 0}
          onClick={() => {
            setDay(0);
            getFixtures({
              date: moment()?.format("YYYY-MM-DD") + "Z",
              // date: "2024-08-24Z",
            }).then((data) => {
              setFixtures(JSON.parse(JSON.stringify(data)));
            });
          }}
        >
          Today
        </DayButton>
      </Row>
      <GamesDiv>
        {fixtures
          ?.sort((a, b) => {
            if (a.competition?.order > b.competition?.order) {
              return 1;
            } else if (a.competition?.order < b.competition?.order) {
              return -1;
            }

            if (a.competition?.name > b.competition?.name) {
              return 1;
            } else if (a.competition?.name < b.competition?.name) {
              return -1;
            }

            let a_date = moment(a.date + " " + a.time);
            let b_date = moment(b.date + " " + b.time);
            // Else go to the 2nd item
            if (a_date < b_date) {
              return -1;
            } else if (a_date > b_date) {
              return 1;
            } else {
              // nothing to split them
              if (a?.home_team?.name < b?.home_team?.name) {
                return -1;
              }
              if (a?.home_team?.name > b?.home_team?.name) {
                return 1;
              }
              return 0;
            }
          })
          ?.map((fixture, index) => {
            return (
              <div key={index}>
                {(index === 0 ||
                  fixtures[index - 1].competition._id !==
                    fixture.competition._id) && (
                  <div>{fixture.competition?.name}</div>
                )}
                <Game
                  selected={gameID === fixture?._id}
                  onClick={() => {
                    setSelecetdRow();
                    let url = "/" + fixture._id;

                    navigate(url);
                  }}
                >
                  <Team right={"true"}>{fixture.home_team?.name}</Team>
                  <Verses>
                    <div>
                      {moment(fixture?.time, "HH:mmZ")?.format("HH:mm")}
                    </div>
                  </Verses>
                  <Team>{fixture.away_team?.name}</Team>
                </Game>
              </div>
            );
          })}
      </GamesDiv>
    </Main>
  );
}

export default Games;
