import { APIProvider } from "./contexts/APIContext";
import { ThemeProvider } from "styled-components";
import Hyper from "./Theme/Hyper";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./Views/Main";
import { RenderProvider } from "./contexts/RenderContext";
function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function App() {
  window.render_server =
    getParameterByName("render_server") ||
    window.ENV?.REACT_APP_RENDER_SERVER ||
    process.env.REACT_APP_RENDER_SERVER;

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ThemeProvider theme={Hyper}>
        <APIProvider>
          <RenderProvider>
            <Routes>
              <Route path="*" element={<Main />}></Route>
            </Routes>
          </RenderProvider>
        </APIProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
