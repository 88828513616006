export default class ClockManual {
  constructor({ ws, match, project, queue, filename, db }) {
    this.ws = ws;
    this.filename = filename?.replace("${GRAPHIC}", "SB_SCORE");
    this.project = project;
    this.queue = queue;
    this.name = "CLOCK";
    this.scene = "CLOCK";
    this.match = match;
    this.last_render = "";
    this.renders = [];
    this.process = true;
    this.db = db;
  }

  render({ match }) {
    this.match = match;
    let data = [];
    data.push(
      this.processGoal({
        home_team_name: this.match?.home_team?.code,
        home_team_bg_colour:
          this.match?.home_team?.variables?.[
            this.match?.home_team_kit + "_kit_bg"
          ] || "",
        home_team_text_colour:
          this.match?.home_team?.variables?.[
            this.match?.home_team_kit + "_kit_text"
          ] || "",
        home_score: 0,
        away_team_name: this.match?.away_team?.code,
        away_team_bg_colour:
          this.match?.away_team?.variables?.[
            this.match?.away_team_kit + "_kit_bg"
          ] || "",
        away_team_text_colour:
          this.match?.away_team?.variables?.[
            this.match?.away_team_kit + "_kit_text"
          ] || "",
        away_score: 0,
      })
    );
    let events = this.match.home_scorers
      ?.map((g) => {
        return { ...g, contestantId: this.match.home_team?.opta_ID };
      })
      .concat(
        this.match.away_scorers?.map((g) => {
          return { ...g, contestantId: this.match.away_team?.opta_ID };
        })
      )
      ?.sort((a, b) => {
        return a.time - b.time;
      });

    let home_score = 0;
    let away_score = 0;
    let home_reds = 0;
    let away_reds = 0;
    for (let i = 0; i < events?.length; i++) {
      let ev = events[i];
      let team =
        this.match?.home_team?.opta_ID === ev.contestantId
          ? "home"
          : this.match?.away_team?.opta_ID === ev.contestantId
          ? "away"
          : "";

      if (team === "home") {
        home_score += 1;
      }
      if (team === "away") {
        away_score += 1;
      }

      data.push(
        this.processGoal({
          home_team_name: this.match?.home_team?.code,
          home_team_bg_colour:
            this.match?.home_team?.variables?.[
              this.match?.home_team_kit + "_kit_bg"
            ] || "",
          home_team_text_colour:
            this.match?.home_team?.variables?.[
              this.match?.home_team_kit + "_kit_text"
            ] || "",
          home_score: home_score,
          away_team_name: this.match?.away_team?.code,
          away_team_bg_colour:
            this.match?.away_team?.variables?.[
              this.match?.away_team_kit + "_kit_bg"
            ] || "",
          away_team_text_colour:
            this.match?.away_team?.variables?.[
              this.match?.away_team_kit + "_kit_text"
            ] || "",
          away_score: away_score,
          home_reds,
          away_reds,
        })
      );
    }
    return data;
  }

  processGoal({
    home_team_name,
    away_team_name,
    home_team_bg_colour,
    away_team_bg_colour,
    home_team_text_colour,
    away_team_text_colour,
    home_score,
    away_score,
    home_reds,
    away_reds,
  }) {
    let data = [
      {
        name: "Home_BG",
        value: {
          colour: home_team_bg_colour,
        },
      },
      {
        name: "Away_BG",
        value: {
          colour: away_team_bg_colour,
        },
      },
      {
        name: "TEXT_COLOUR_HOME",
        value: {
          colour: home_team_text_colour,
        },
      },
      {
        name: "TEXT_COLOUR_AWAY",
        value: {
          colour: away_team_text_colour,
        },
      },
      {
        name: "Home_Score",
        value: { text: home_score },
      },
      {
        name: "Away_Score",
        value: { text: away_score },
      },
      { name: "Home_Name", value: { text: home_team_name } },
      { name: "Away_Name", value: { text: away_team_name } },
      { name: "TOGGLE_CLOCK", value: { visible: false } },
      {
        name: "Home_Red_1",
        value: { position: { y: 47, x: 244 }, visible: home_reds >= 1 },
      },
      {
        name: "Home_Red_2",
        value: { position: { y: 47, x: 225 }, visible: home_reds >= 2 },
      },
      {
        name: "Home_Red_3",
        value: { position: { y: 47, x: 206 }, visible: home_reds >= 3 },
      },
      {
        name: "Home_Red_4",
        value: { position: { y: 47, x: 187 }, visible: home_reds >= 4 },
      },
      {
        name: "Away_Red_1",
        value: { position: { y: 47, x: 167 }, visible: away_reds >= 1 },
      },
      {
        name: "Away_Red_2",
        value: { position: { y: 47, x: 187 }, visible: away_reds >= 2 },
      },
      {
        name: "Away_Red_3",
        value: { position: { y: 47, x: 206 }, visible: away_reds >= 3 },
      },
      {
        name: "Away_Red_4",
        value: { position: { y: 47, x: 225 }, visible: away_reds >= 4 },
      },
    ];
    return {
      show_png: true,
      // directory:
      //   this.match.competition.name +
      //   "/" +
      //   this.match.date +
      //   "/" +
      //   this.match.description +
      //   "/",
      filename: this.filename?.replace(
        "SB_SCORE",
        "SB_SCORE_" +
          home_score +
          "_" +
          away_score +
          (home_reds > 0 || away_reds > 0
            ? "_REDS_" + home_reds + "_" + away_reds
            : "")
      ),
      template: this.scene,
      project: this.project,
      queue: this.queue,
      data,
      pause: 50,
      end_blank_frame: true,
    };
  }
}
