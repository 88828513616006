import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "./UserContext";

const RenderContext = React.createContext([{}, () => {}]);

const RenderProvider = (props) => {
  const [ws, setWS] = useState(null);
  const [wsStatus, setWSStatus] = useState(-1);
  const { loggedIn, user } = useContext(UserContext);
  useEffect(() => {
    if (loggedIn) {
      debugger;
      connect();
    }
  }, [loggedIn]);

  function connect() {
    if (window.render_server) {
      console.log("CG connecting to render server");

      setWS(new WebSocket(window.render_server));
    }
  }

  useEffect(() => {
    if (ws) {
      let timeout;
      let interval;
      ws.onopen = () => {
        setWSStatus(1);
        console.log("Render on open");

        // console.log("connected");
        clearInterval(interval);
        interval = setInterval(() => {
          sendData({
            keep_alive: Date.now(),
            group: window.id,
          });
        }, 20000);
      };
      ws.onmessage = (data) => {
        console.log("Render on message");
      };
      ws.onerror = (err) => {
        console.log("Render on error");
        ws.close();
      };
      ws.onclose = (data) => {
        setWSStatus(0);
        console.log("Render on close");
        clearTimeout(timeout);
        clearInterval(interval);
        timeout = setTimeout(() => {
          connect();
        }, 1000);
      };
    }
  }, [ws]);

  function sendData(data) {
    if (ws) {
      ws.send(JSON.stringify(data));
    }
  }

  return (
    <RenderContext.Provider
      value={{
        sendData,
        wsStatus,
      }}
    >
      {props.children}
    </RenderContext.Provider>
  );
};

export { RenderContext, RenderProvider };
