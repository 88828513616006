import React, { useContext, useState } from "react";
import styled from "styled-components";
import logo from "../../Images/HyperLogo.png";
import { UserContext } from "../../contexts/UserContext";

const Main = styled.div`
  height: 40px;
  background-color: #202731;
  z-index: 1000;
  position: -webkit-sticky;
  position: sticky;
  box-shadow: 0px 4px 12px -20px rgba(0, 0, 0, 0.2),
    0px 8px 20px 2px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  border-bottom: 1px solid #030304;
`;

const HyperLogo = styled.img`
  margin-left: 20px;
  height: 50%;
`;

const Column = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: ${(props) => {
    if (props.right) return "flex-end";
    if (props.left) return "flex-start";
  }};
  align-items: center;
`;

const SignOut = styled.div`
  display: flex;
  border-radius: 4px;
  border: 1px solid red;
  padding: 8px;
  margin-left: 10px;
  cursor: pointer;
  :hover {
    background-color: red;
  }
`;
export default function Header({}) {
  const { loggedIn, user, signOut } = useContext(UserContext);

  return (
    <Main>
      <Column>
        <HyperLogo src={logo} />
      </Column>
      <Column right={"true"}>
        {user && (
          <>
            {user.username}
            <SignOut
              onClick={() => {
                signOut();
              }}
            >
              Sign Out
            </SignOut>
          </>
        )}
      </Column>
    </Main>
  );
}
