export default class Versus {
  constructor({ ws, match, project, queue, filename }) {
    this.ws = ws;
    this.filename = filename?.replace("${GRAPHIC}", "VS_LOWER");
    this.project = project;
    this.queue = queue;
    this.name = "VERSUS";
    this.scene = "VERSUS";
    this.match = match;
    this.last_render = "";
    this.process = true;
  }

  render({ match }) {
    this.match = match;
    let data_obj = [];
    if (this.process) {
      let data = [
        {
          name: "COMPETITION_NAME",
          value: { text: this.match?.competition?.name || "" },
        },
        {
          name: "COMPETITION_NAME",
          value: { text: this.match?.competition?.name || "" },
        },
        {
          name: "Middle_strap",
          value: { colour: this.match?.competition?.colour || "" },
        },
        {
          name: "COMP_COLOUR",
          value: { colour: this.match?.competition?.colour || "" },
        },
        {
          name: "Home_Team_Name",
          value: { text: this.match?.home_team?.name },
        },
        {
          name: "Away_Team_Name",
          value: { text: this.match?.away_team?.name },
        },
        {
          name: "Home_Badge",
          value: {
            image: "${user}" + this.match?.home_team?.variables?.badge,
          },
        },
        {
          name: "Away_Badge",
          value: {
            image: "${user}" + this.match?.away_team?.variables?.badge,
          },
        },
        {
          name: "VENUE",
          value: {
            text: this.match?.stadium?.name || this.match?.stadium?.opta_name,
          },
        },
      ];

      data_obj.push({
        // directory:
        //   this.match.competition.name +
        //   "/" +
        //   this.match.date +
        //   "/" +
        //   this.match.description +
        //   "/",
        filename: this.filename,
        template: this.scene,
        project: this.project,
        queue: this.queue,
        data,
        pause: 50 * 5,
        end_blank_frame: true,
      });
    }
    return data_obj;
  }
}
