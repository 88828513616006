export default class Penalties {
  constructor({ ws, match, project, queue, filename, db }) {
    this.ws = ws;
    this.filename = filename?.replace("${GRAPHIC}", "PS_PENALTIES");
    this.project = project;
    this.queue = queue;
    this.name = "PENALTIES";
    this.scene = "PENALTIES";
    this.match = match;
    this.last_render = "";
    this.renders = [];
    this.process = true;
    this.db = db;
  }

  render({ match }) {
    this.match = match;
    let data = [];
    if (
      this.process &&
      (this.match?.home_penalties?.length > 0 ||
        this.match?.away_penalties?.length > 0)
    ) {
      data.push(
        this.processGoal({
          home_team_name: this.match?.home_team?.code,
          home_team_bg_colour:
            this.match?.home_team?.variables?.[
              this.match?.home_team_kit + "_kit_bg"
            ] || "",
          home_team_text_colour:
            this.match?.home_team?.variables?.[
              this.match?.home_team_kit + "_kit_text"
            ] || "",
          home_score: 0,
          away_team_name: this.match?.away_team?.code,
          away_team_bg_colour:
            this.match?.away_team?.variables?.[
              this.match?.away_team_kit + "_kit_bg"
            ] || "",
          away_team_text_colour:
            this.match?.away_team?.variables?.[
              this.match?.away_team_kit + "_kit_text"
            ] || "",
          away_score: 0,
        })
      );

      let pens = [];
      this.match.home_penalties?.map((pen) => {
        pens.push({ ...pen, team: this.match?.home_team });
      });
      this.match.away_penalties?.map((pen) => {
        pens.push({ ...pen, team: this.match?.away_team });
      });
      pens = pens.sort((a, b) => {
        return a?.time - b?.time;
      });
      let processed_pens = [];
      for (let i = 0; i < pens?.length; i++) {
        let pen = pens[i];
        processed_pens.push(pen);
        data.push(
          this.processGoal({
            home_team_name: this.match?.home_team?.code,
            home_team_bg_colour:
              this.match?.home_team?.variables?.[
                this.match?.home_team_kit + "_kit_bg"
              ] || "",
            home_team_text_colour:
              this.match?.home_team?.variables?.[
                this.match?.home_team_kit + "_kit_text"
              ] || "",
            home_score:
              processed_pens?.filter(
                (p) =>
                  p.type === 1 && p?.team?._id === this.match?.home_team?._id
              )?.length ?? 0,
            away_team_name: this.match?.away_team?.code,
            away_team_bg_colour:
              this.match?.away_team?.variables?.[
                this.match?.away_team_kit + "_kit_bg"
              ] || "",
            away_team_text_colour:
              this.match?.away_team?.variables?.[
                this.match?.away_team_kit + "_kit_text"
              ] || "",
            away_score:
              processed_pens?.filter(
                (p) =>
                  p.type === 1 && p?.team?._id === this.match?.away_team?._id
              )?.length ?? 0,
            pens: processed_pens,
          })
        );
      }
    }
    return data;
  }

  processGoal({
    home_team_name,
    away_team_name,
    home_team_bg_colour,
    away_team_bg_colour,
    home_team_text_colour,
    away_team_text_colour,
    home_score,
    away_score,
    pens,
  }) {
    let data = [
      {
        name: "COMP_COLOUR",
        value: { colour: this.match?.competition?.colour || "" },
      },
      {
        name: "Home_BG",
        value: {
          colour: home_team_bg_colour,
        },
      },
      {
        name: "Away_BG",
        value: {
          colour: away_team_bg_colour,
        },
      },
      {
        name: "TEXT_COLOUR_HOME",
        value: {
          colour: home_team_text_colour,
        },
      },
      {
        name: "TEXT_COLOUR_AWAY",
        value: {
          colour: away_team_text_colour,
        },
      },
      {
        name: "Home_Score",
        value: { text: home_score },
      },
      {
        name: "Away_Score",
        value: { text: away_score },
      },
      { name: "Home_Name", value: { text: home_team_name } },
      { name: "Away_Name", value: { text: away_team_name } },
      { name: "TOGGLE_CLOCK", value: { visible: false } },
      {
        name: "Home_Red_1",
        value: { position: { y: 47, x: 244 }, visible: false },
      },
      {
        name: "Home_Red_2",
        value: { position: { y: 47, x: 225 }, visible: false },
      },
      {
        name: "Home_Red_3",
        value: { position: { y: 47, x: 206 }, visible: false },
      },
      {
        name: "Home_Red_4",
        value: { position: { y: 47, x: 187 }, visible: false },
      },
      {
        name: "Away_Red_1",
        value: { position: { y: 47, x: 167 }, visible: false },
      },
      {
        name: "Away_Red_2",
        value: { position: { y: 47, x: 187 }, visible: false },
      },
      {
        name: "Away_Red_3",
        value: { position: { y: 47, x: 206 }, visible: false },
      },
      {
        name: "Away_Red_4",
        value: { position: { y: 47, x: 225 }, visible: false },
      },
    ];

    for (let i = 0; i < 5; i++) {
      let index = i;
      if (pens?.length > 10) {
        index += 5;
      }
      if (pens?.length > 15) {
        index += 10;
      }
      if (pens?.length > 20) {
        index += 15;
      }
      data.push({
        name: "Home-Attempt_" + (i + 1),
        value: {
          text:
            pens?.find(
              (p) =>
                p?.team?._id === this.match?.home_team?._id &&
                p?.index === index
            )?.type ?? -1,
        },
      });
      data.push({
        name: "Away-Attempt_" + (i + 1),
        value: {
          text:
            pens?.find(
              (p) =>
                p?.team?._id === this.match?.away_team?._id &&
                p?.index === index
            )?.type ?? -1,
        },
      });
      data.push({
        name: "Home-Attempt-txt_" + (i + 1),
        value: {
          text: index + 1,
        },
      });
      data.push({
        name: "Away-Attempt-txt_" + (i + 1),
        value: {
          text: index + 1,
        },
      });
    }

    return {
      filename: this.filename?.replace(
        "PS_PENALTIES",
        "PS_PENALTIES_" +
          home_score +
          "_" +
          away_score +
          "_PEN_" +
          (pens?.length ?? 0)
      ),
      template: this.scene,
      project: this.project,
      queue: this.queue,
      data,
      pause: 50,
      end_blank_frame: true,
    };
  }
}
