export default class MatchID {
  constructor({ match, ws, project, queue, db, filename }) {
    this.ws = ws;
    this.filename = filename?.replace("${GRAPHIC}", "MATCH_HL_SLATE");
    this.extened_filename = filename?.replace(
      "${GRAPHIC}",
      "EXTENDED_MATCH_HL_SLATE"
    );
    this.project = project;
    this.queue = queue;
    this.name = "MatchID";
    this.scene = "SLATE";
    this.match = match;
    this.last_render = "";
    this.extened_last_render = "";
    this.process = true;
  }

  render({ match }) {
    this.match = match;
    let data = [];
    data.push(this.renderShort());
    data.push(this.renderExtended());
    return data;
  }
  renderShort() {
    let data = [
      {
        name: "COMPETITION_NAME",
        value: { text: this.match?.competition?.name || "" },
      },
      {
        name: "ANIM_COMP_COLOUR",
        value: {
          colour: this.match?.competition?.colour || "",
        },
      },
      {
        name: "HOME_COMP_COLOUR",
        value: { colour: this.match?.competition?.colour || "" },
      },
      {
        name: "AWAY_COMP_COLOUR",
        value: { colour: this.match?.competition?.colour || "" },
      },
      {
        name: "KICK_OFF_TEXT",
        value: {
          text: "MATCH HIGHLIGHTS",
        },
      },
      {
        name: "HOME_TEAM",
        value: { text: this.match?.home_team?.name },
      },
      {
        name: "AWAY_TEAM",
        value: { text: this.match?.away_team?.name },
      },
      {
        name: "HOME_BADGE",
        value: {
          image: "${user}" + this.match?.home_team?.variables?.badge,
        },
      },
      {
        name: "AWAY_BADGE",
        value: {
          image: "${user}" + this.match?.away_team?.variables?.badge,
        },
      },
      {
        name: "VENUE",
        value: {
          text: this.match?.stadium?.name || this.match?.stadium?.opta_name,
        },
      },
    ];

    return {
      filename: this.filename,
      template: this.scene,
      project: this.project,
      queue: this.queue,
      data,
      pause: 50 * 10,
      end_blank_frame: true,
    };
  }

  renderExtended() {
    let data = [
      {
        name: "COMPETITION_NAME",
        value: { text: this.match?.competition?.name || "" },
      },
      {
        name: "ANIM_COMP_COLOUR",
        value: { colour: this.match?.competition?.colour || "" },
      },
      {
        name: "HOME_COMP_COLOUR",
        value: { colour: this.match?.competition?.colour || "" },
      },
      {
        name: "AWAY_COMP_COLOUR",
        value: { colour: this.match?.competition?.colour || "" },
      },
      {
        name: "KICK_OFF_TEXT",
        value: {
          text: "EXTENDED MATCH HIGHLIGHTS",
        },
      },
      {
        name: "HOME_TEAM",
        value: { text: this.match?.home_team?.name },
      },
      {
        name: "AWAY_TEAM",
        value: { text: this.match?.away_team?.name },
      },
      {
        name: "HOME_BADGE",
        value: {
          image: "${user}" + this.match?.home_team?.variables?.badge,
        },
      },
      {
        name: "AWAY_BADGE",
        value: {
          image: "${user}" + this.match?.away_team?.variables?.badge,
        },
      },
      {
        name: "VENUE",
        value: {
          text: this.match?.stadium?.name || this.match?.stadium?.opta_name,
        },
      },
    ];

    return {
      filename: this.extened_filename,
      template: this.scene,
      project: this.project,
      queue: this.queue,
      data,
      pause: 50 * 10,
      end_blank_frame: true,
    };
  }
}
