export default class ScoreSlate {
  constructor({ match, ws, project, queue, db, filename }) {
    this.ws = ws;
    this.filename = filename?.replace("${GRAPHIC}", "MATCH_FT_SLATE");
    this.project = project;
    this.queue = queue;
    this.name = "ScoreSlate";
    this.scene = "FT_SCORE_SLATE";
    this.match = match;
    this.last_render = "";
    this.extened_last_render = "";
    this.process = true;
    this.db = db;
  }

  render({ match }) {
    this.match = match;
    let data_obj = [];

    if (this.process) {
      let data = [
        {
          name: "COMPETITION_NAME",
          value: { text: this.match?.competition?.name || "" },
        },
        {
          name: "Middle_strap",
          value: { colour: this.match?.competition?.colour || "" },
        },
        {
          name: "ANIM_COMP_COLOUR",
          value: {
            colour: this.match?.competition?.colour || "",
          },
        },
        {
          name: "COMP_COLOUR",
          value: {
            colour: this.match?.competition?.colour || "",
          },
        },
        {
          name: "Home_Team_Name",
          value: { text: this.match?.home_team?.name },
        },
        {
          name: "Away_Team_Name",
          value: { text: this.match?.away_team?.name },
        },
        {
          name: "Home_Score",
          value: { text: this.match?.home_scorers?.length },
        },
        {
          name: "Away_Score",
          value: { text: this.match?.away_scorers?.length },
        },
        {
          name: "Home_Badge",
          value: {
            image: "${user}" + this.match?.home_team?.variables?.badge,
          },
        },
        {
          name: "Away_Badge",
          value: {
            image: "${user}" + this.match?.away_team?.variables?.badge,
          },
        },
        {
          name: "SUB_TITLE",
          value: {
            text: "",
          },
        },
      ];
      if (
        this?.match?.home_penalties?.length > 0 ||
        this.match?.away_penalties?.length > 0
      ) {
        let penalty_caption = "";
        let home_penalties =
          this?.match?.home_penalties?.filter((goal) => goal.type > 0).length ||
          0;
        let away_penalties =
          this?.match?.away_penalties?.filter((goal) => goal.type > 0).length ||
          0;
        if (home_penalties > away_penalties) {
          penalty_caption =
            this?.match?.home_team?.name +
            " win " +
            home_penalties +
            "-" +
            away_penalties +
            " on penalties";
        } else if (away_penalties > home_penalties) {
          penalty_caption =
            this?.match?.away_team?.name +
            " win " +
            away_penalties +
            "-" +
            home_penalties +
            " on penalties";
        }
        data.find((d) => d?.name === "SUB_TITLE").value = {
          text: penalty_caption,
        };
      }

      data_obj.push({
        filename: this.filename,
        template: this.scene,
        project: this.project,
        queue: this.queue,
        data,
        pause: 50 * 10,
        end_blank_frame: true,
      });
    }
    return data_obj;
  }
}
