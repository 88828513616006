import { gql, useLazyQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Match from "../Match";
import { Button, ScrollBar } from "../Theme/Hyper";
import { RenderContext } from "../contexts/RenderContext";
const LINEUP_FIELDS = gql`
  fragment LINEUP_FIELDS on LineupObject {
    home {
      squad {
        _id
        shirt_number
        opta_shirt_number
        player {
          _id
          opta_ID
          first_name
          last_name
          first_name_upper
          last_name_upper
          opta_first_name
          opta_last_name
          nationality {
            _id
            opta_code
            opta_name
          }
        }
      }
      order
      yellow_card
      dbl_yellow_card
      red_card
      sub_order
      subbed
      captain
    }
    away {
      squad {
        _id

        shirt_number
        opta_shirt_number
        player {
          _id
          opta_ID
          first_name
          last_name
          first_name_upper
          last_name_upper
          opta_first_name
          opta_last_name
          nationality {
            _id
            opta_code
            opta_name
          }
        }
      }
      order
      yellow_card
      dbl_yellow_card
      red_card
      sub_order
      subbed
      captain
    }
  }
`;
const GAME_BY_ID = gql`
  ${LINEUP_FIELDS}
  query GameByID($id: String) {
    gameById(id: $id) {
      _id
      time
      description
      date
      home_team_kit
      away_team_kit
      opta_team_stats
      opta_player_stats
      stadium {
        opta_name
        name
      }
      competition {
        _id
        name
        badge
        competition_type
        colour
        code
      }
      officials {
        official {
          first_name
          last_name
        }
        type
      }
      home_scorers
      away_scorers
      home_penalties
      away_penalties
      lineup {
        ...LINEUP_FIELDS
      }
      home_team {
        _id
        opta_ID
        name
        short_name
        code
        variables
      }
      away_team {
        _id
        opta_ID
        name
        short_name
        code
        variables
      }
      live_data
      home_formation
      away_formation
      season {
        _id
      }
      commentators {
        first_name
        last_name
      }
      config
      agg_home_score
      agg_away_score
      leg
    }
  }
`;

const GAME_SUBSCRIPTION = gql`
  ${LINEUP_FIELDS}
  subscription GameUpdated($id: String!) {
    gameUpdated(_id: $id) {
      _id
      home_team_kit
      away_team_kit
      lineup {
        ...LINEUP_FIELDS
      }
      live_data
      opta_team_stats
      opta_player_stats
      home_scorers
      away_scorers
      home_penalties
      away_penalties
      home_formation
      away_formation
      agg_home_score
      agg_away_score
      leg
      season {
        _id
      }
      commentators {
        first_name
        last_name
      }
      home_team {
        _id
        opta_ID
        name
        short_name
        code
        variables
      }
      away_team {
        _id
        opta_ID
        name
        short_name
        code
        variables
      }
    }
  }
`;
const GraphicsDiv = styled(ScrollBar)`
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 1em;
  box-sizing: border-box;
  overflow-y: auto;
  height: 95vh;
`;

const LayoutRow = styled.div`
  display: flex;
  width: 100%;
`;
function Game({ selectedRow, setSelecetdRow }) {
  let { gameID } = useParams();
  const [game, setGame] = useState();
  const [gameGraphics, setGameGraphics] = useState();

  const { sendData } = useContext(RenderContext);
  const [getGame, { data, subscribeToMore }] = useLazyQuery(GAME_BY_ID, {
    fetchPolicy: "network-first",
  });

  useEffect(() => {
    if (gameID) {
      getGame({ variables: { id: gameID } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameID]);

  useEffect(() => {
    if (data) {
      setGame(data.gameById);
      debugger;
      setGameGraphics(
        new Match({
          match: data?.gameById,
          project:
            window.ENV?.REACT_APP_PROJECT_ID ||
            process.env.REACT_APP_PROJECT_ID,
          queue:
            window.ENV?.REACT_APP_RENDER_QUEUE ||
            process.env.REACT_APP_RENDER_QUEUE,
        })
      );
    }
  }, [data]);

  useEffect(() => {
    let unsubscribe;
    if (subscribeToMore) {
      console.log("Subscribed");
      unsubscribe = subscribeToMore({
        document: GAME_SUBSCRIPTION,
        variables: { id: gameID },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;

          const updatedGame = subscriptionData.data.gameUpdated;
          let newGame = {
            gameById: { ...prev.gameById, ...updatedGame },
          };

          return newGame;
        },
      });
    }
    return () => {
      console.log("Unsubscribed");
      unsubscribe && unsubscribe();
    };
  }, [subscribeToMore, gameID]);
  return (
    <LayoutRow>
      <GraphicsDiv>
        {gameGraphics?.graphics?.map((g) => {
          return (
            <Graphic
              graphic={g}
              game={game}
              selectedRow={selectedRow}
              setSelecetdRow={setSelecetdRow}
            />
          );
        })}
      </GraphicsDiv>
      {selectedRow && (
        <div>
          {selectedRow?.filename}{" "}
          <Button
            onClick={() => {
              let render_obj = { ...selectedRow };
              render_obj.filename = render_obj.filename?.replace(
                "{TIMECODE}",
                Date.now()
              );
              render_obj.filename = render_obj.filename?.replace(
                "{TIMECODE}",
                ""
              );
              render_obj.filename = render_obj.filename?.replaceAll(" ", "_");
              render_obj.filename = render_obj.filename + ".mov";
              sendData(render_obj);
            }}
          >
            Render .mov
          </Button>
          <Button
            onClick={() => {
              let render_obj = { ...selectedRow };
              render_obj.filename = render_obj.filename?.replace(
                "{TIMECODE}",
                Date.now()
              );
              render_obj.filename = render_obj.filename?.replace(
                "{TIMECODE}",
                ""
              );
              render_obj.filename = render_obj.filename?.replaceAll(" ", "_");
              render_obj.filename = render_obj.filename + ".png";
              render_obj.snapshot = true;
              sendData(render_obj);
            }}
          >
            Render .png
          </Button>
        </div>
      )}
    </LayoutRow>
  );
}
const Row = styled.div`
  display: flex;
  width: 100%;
  padding: 0.5em;
  justify-content: center;
  font-size: 1em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  background-color: ${(props) =>
    props.sub ? " #181d25" : props.selected ? "#db0a41" : "#202731"};
  border-bottom: 2px solid #181d25;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
`;

function Graphic({ graphic, game, selectedRow, setSelecetdRow }) {
  return graphic.render({ match: game })?.map((data) => {
    return (
      <Row
        selected={selectedRow?.filename === data.filename}
        onClick={() => {
          setSelecetdRow(data);
        }}
      >
        {data.filename}
      </Row>
    );
  });
}

export default Game;
