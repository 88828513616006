import React, { useContext, useState, useEffect } from "react";
import { useParams, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import Games from "../components/Games";
import Game from "../components/Game";
import Header from "../components/Header/Header";
const Content = styled.div`
  display: flex;
  box-sizing: border-box;
  flex: 1;
  width: 100vw;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
`;
export default function Main() {
  const [selectedRow, setSelecetdRow] = useState();
  return (
    <Content>
      <Header />
      <Routes>
        <Route
          path="/:gameID"
          element={
            <Row>
              <Games
                selectedRow={selectedRow}
                setSelecetdRow={setSelecetdRow}
              />
              <Game selectedRow={selectedRow} setSelecetdRow={setSelecetdRow} />
            </Row>
          }
        ></Route>
        <Route
          path="/"
          element={
            <Games selectedRow={selectedRow} setSelecetdRow={setSelecetdRow} />
          }
        ></Route>
      </Routes>
    </Content>
  );
}
